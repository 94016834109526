<template>
  <modal v-model="showModal" dimmer maskClose hideClose :hideFooter="false" title="奖品" @on-ok="onOk" @on-cancel="onCancel">
    <div class="group-item">
      <span class="required">名称：</span>
      <y-input v-model="title"></y-input>
    </div>
    <div class="group-item">
      <span class="required">价格：</span>
      <y-input v-model="price"></y-input>
    </div>
    <div class="group-item">
      <span class="required">图片链接：</span>
      <y-input v-model="imgUrl"></y-input>
    </div>
    <div class="group-item">
      <span class="required">商品链接：</span>
      <y-input v-model="goodsUrl"></y-input>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
import YInput from '../common/input/input'
export default {
  components: {Modal, YInput},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    value: String
  },
  data () {
    return {
      showModal: this.show,
      title: '',
      price: '',
      imgUrl: '',
      goodsUrl: ''
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    onOk () {
      if (this.title) {
        this.$emit('on-ok', {
          title: this.title,
          price: this.price,
          imgUrl: this.imgUrl,
          goodsUrl: this.goodsUrl
        })
        this.showModal = false
      }
    },
    onCancel () {
      this.showModal = false
    }
  }
}
</script>
<style lang="less">
.group-item {
  padding: 15px;
  display: flex;
  align-items: center;
  input {
    width: 300px;
  }
  .required {
    position: relative;
    &::before {
      content: "*";
      color: red;
      transform: translate(-100%, 50%);
    }
  }
}
</style>
