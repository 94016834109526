<template>
  <div class="page-box">
    <div class="p-title">
      <span class="label">奖品中心</span>
      <span class="add-p" @click.stop="handleAddPrize">+添加奖品</span>
    </div>
    <div class="p-list">
      <div class="p-item" v-for="item in prizeList" :key="item.id">
        <div class="inner">
          <div class="title">{{item.title}}</div>
          <div class="img"><img :src="item.imgUrl" alt=""></div>
          <div class="ft">
            <div class="price"><span>价格:</span>{{item.price}}</div>
            <div class="link"><a target="_blank" :href="item.goodsUrl">物品链接</a></div>
          </div>
        </div>
      </div>
    </div>
    <prize-add-win :show="showAddWin" @on-hide="showAddWin=false" @on-ok="onAdd"></prize-add-win>
  </div>
</template>
<script>
import PrizeAddWin from './prize-add-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {PrizeAddWin},
  data () {
    return {
      prizeList: [],
      showAddWin: false
    }
  },
  mounted () {
    document.title = '奖品中心'
    this.loadPrizeList()
  },
  methods: {
    loadPrizeList () {
      this.$http.get(`${this.httpRoot}/prize/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.prizeList = res.prizeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleAddPrize () {
      this.showAddWin = true
    },
    onAdd (prize) {
      this.$http.post(`${this.httpRoot}/prize/add`, prize, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadPrizeList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.p-title {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  .label {
    background: #dfdfdf;
    padding: 3px 5px;
  }
  .add-p {
    margin-left: 5px;
    padding: 3px 5px;
    background-color: #7ba0e4;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #ffe8e8;
    }
  }
}
.p-list {
  display: flex;
  flex-wrap: wrap;
  .p-item {
    width: 25%;
    font-size: 13px;
    .inner {
      padding: 15px 10px;
    }
    .ft {
      display: flex;
    }
    img {
      width: 100%;
    }
    .price {
      margin-right: 10px;
    }
  }
}
</style>
